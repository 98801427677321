import { useEffect, React } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./project.scss";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { useModal } from "../../hooks/modalsHooks";
import { getProjectTemplateName } from "../../utils/projectUtils";
import { logEvent } from "../../utils/amplitudeUtlis";
import ProjectCard from "../../components/sct/Card/ProjectCard";
import { useGetProjectsQuery } from "../../services/projectService";
import Loading from "../../components/LoadingSkeleton/Loading";
import { useGetTenantQuery } from "../../services/tenantService";
import { useNavigate } from "react-router-dom";

export default function ProjectPage() {
    const { data: projects, isLoading, refetch } = useGetProjectsQuery();
    const data = projects?.data;
    const { showModal } = useModal();
    const { data: tenantData } = useGetTenantQuery();
    const navigate = useNavigate();

    useEffect(() => {
        refetch();
    }, [refetch]);

    useEffect(() => {
        if (tenantData && tenantData.data && !tenantData.data.is_tier_active) {
            toast.error("Your subscription has expired.", {
                autoClose: false,
                onClick: () => navigate("/settings/billing"),
            });
        }
    }, [tenantData]);

    const handleCreateNewProject = async () => {
        logEvent({
            eventName: "project_creation_initiated",
            eventProperties: {},
        });
        showModal({
            modalType: "CreateProjectModal",
            modalProps: {
                projectType: "token-service",
            },
        });
    };

    if (isLoading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <Loading />
            </div>
        );
    }

    return (
        <div className="min-vh-100 w-100 px-4">
            <div className="mt-3">
                <Typography variant="body1">Overview</Typography>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <Typography variant="h1">All Projects</Typography>
                        <div className="ps-md-3 ps-0 subtitle mt-2 mt-md-0">
                            {data?.length > 0
                                ? `${data?.length} Active Projects`
                                : "0 active projects"}
                        </div>
                    </div>
                    <div className="me-0 mb-2 mb-md-0 p-md-0 p-4 pt-3">
                        <Button
                            type="button"
                            variant="primary"
                            fullWidth={false}
                            onClick={handleCreateNewProject}
                        >
                            Create new project
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mt-4 row">
                {data?.length > 0 ? (
                    <>
                        {data &&
                            data.map((item) => (
                                <div
                                    key={item.id}
                                    className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-wrap mb-4"
                                >
                                    <ProjectCard
                                        title={item.name}
                                        subtitle={getProjectTemplateName(
                                            item.template,
                                        )}
                                        time={`Created on ${new Date(
                                            item.created_at,
                                        )
                                            .toLocaleDateString("en-US")
                                            .split("/")
                                            .join("-")}`}
                                        image="/images/testLogo.png"
                                        linkText="View project"
                                        link={`/overview/${item.id}`}
                                    />
                                </div>
                            ))}
                    </>
                ) : (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 d-flex flex-wrap ">
                        <ProjectCard
                            height="20"
                            width="20"
                            title="Add new project"
                            subtitle="Create your first project"
                            image="/icons/add-circle.svg"
                            linkText="Start"
                            onClick={handleCreateNewProject}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
