import AppLayout from "../components/AppLayout";
import CustomerLayout from "../components/CustomerLayout";
import RequireAuth from "../components/routing/PrivateRoute";
import PublicOnly from "../components/routing/PublicOnlyRoute";
import RequireCustomerAuth from "../components/taas/customer/routing/PrivateRoute";
import SignInPage from "../pages/auth/SignInPage";
import SignUpPage from "../pages/auth/SignUpPage";
import SignoutPage from "../pages/auth/SignoutPage";
import LandingPage from "../pages/customer/Landing/LandingPage";
import TraceDetailPage from "../pages/customer/Trace/TraceDetailPage";
import TracePage from "../pages/customer/Trace/TracePage";
import OnboardingPage from "../pages/onboarding/OnboardingPage";
import CreateProject from "../pages/project/CreateProject";
import ProjectDetailPage from "../pages/project/ProjectDetailPage";
import ProjectPage from "../pages/project/ProjectPage";
import AddTransferPage from "../pages/sct/TransferTab/AddTransferPage";
import EditExistingMaterial from "../pages/sct/ConfigureMaterialTab/ExistingMaterial/EditExistingMaterial";
import SettingsPage from "../pages/settings/SettingsPage";
import BillingPage from "../pages/settings/billing/BillingPage";
import UserPage from "../pages/settings/users/UserPage";
import ProjectDashboardPage from "../pages/taas/Admin/Overview/ProjectDashboardPage";
import CustomerAuthPage from "../pages/taas/Customer/CustomerAuth/CustomerAuthPage";
import TaasSignoutPage from "../pages/taas/Customer/CustomerAuth/TaasSignoutPage";
import CustomerDashboardPage from "../pages/taas/Customer/CustomerDashboardPage/CustomerDashboardPage";
import TokenPurchasePage from "../pages/taas/Customer/CustomerPurchase/TokenPurchasePage";
import FundingDetail from "../pages/taas/Customer/FundingDetailPage/FundingDetail";
import TaaSCustomerAppLayout from "../pages/taas/Customer/TaaSCustomerAppLayout";

// import TradeFiPoolDetailPage from "../pages/tradeFinance/tradeFiPoolDetailPage/tradeFiPoolDetailPage";

import { getRouteObj } from "../utils/routeUtils";

export const routesConfig = [
    {
        title: "",
        path: "/",
        element: <AppLayout />,
        routeId: "SUPPLY_CHAIN_LAYOUT",
        children: [
            {
                title: "Onboarding",
                path: "onboarding",
                element: (
                    <RequireAuth>
                        <OnboardingPage />
                    </RequireAuth>
                ),
                routeId: "ONBOARDING",
            },
            {
                title: "Overview",
                path: "overview",
                element: (
                    <RequireAuth>
                        <ProjectPage />
                    </RequireAuth>
                ),
                routeId: "PROJECT_OVERVIEW",
            },
            {
                title: "Billing",
                path: "billing",
                element: (
                    <RequireAuth>
                        <BillingPage />
                    </RequireAuth>
                ),
                routeId: "PROJECT_OVERVIEW",
            },
            {
                title: "Users",
                path: "users",
                element: (
                    <RequireAuth>
                        <UserPage />
                    </RequireAuth>
                ),
                routeId: "USERS",
            },
            {
                title: "Settings",
                path: "settings",
                routeId: "SETTINGS",
                element: (
                    <RequireAuth>
                        <SettingsPage />
                    </RequireAuth>
                ),
                children: [
                    {
                        title: "Settings Page",
                        path: ":tabName",
                        element: (
                            <RequireAuth>
                                <SettingsPage />
                            </RequireAuth>
                        ),
                        routeId: "SETTINGS_PAGE",
                    },
                ],
            },
            {
                title: "CreateProjectByType",
                path: "start/:projectType",
                element: (
                    <RequireAuth>
                        <CreateProject />
                    </RequireAuth>
                ),
                routeId: "CREATE_PROJECT",
            },
            {
                title: "CreateProject",
                path: "createproject",
                element: (
                    <RequireAuth>
                        <CreateProject />
                    </RequireAuth>
                ),
                routeId: "CREATE_PROJECT",
            },
            {
                title: "projectDetail",
                path: "overview/:id",
                element: (
                    <RequireAuth>
                        <ProjectDetailPage />
                    </RequireAuth>
                ),
                routeId: "PROJECT_DETAIL",
                children: [
                    {
                        title: "Project Detail Tab",
                        path: ":tabName",
                        element: (
                            <RequireAuth>
                                <ProjectDashboardPage />
                            </RequireAuth>
                        ),
                        routeId: "PROJECT_OVERVIEW_TAB",
                    },
                ],
            },
            {
                title: "Supply chain traceability - Asset Type - Edit",
                path: "overview/:id/asset-type/:assetTypeId/edit",
                element: (
                    <RequireAuth>
                        <EditExistingMaterial />
                    </RequireAuth>
                ),
                routeId: "CREATE_ASSET",
            },
            {
                title: "createTransfer",
                path: "overview/:id/addTransfer",
                element: (
                    <RequireAuth>
                        <AddTransferPage />
                    </RequireAuth>
                ),
                routeId: "CREATE_TRANSFER",
            },
        ],
    },
    {
        title: "",
        path: "/app",
        element: <CustomerLayout />,
        routeId: "APP_LAYOUT",
        children: [
            {
                title: "Landing",
                path: ":projectId",
                element: <LandingPage />,
                routeId: "LANDING_PAGE",
            },
            {
                title: "Trace",
                path: ":projectId/trace/:assetId",
                element: <TracePage />,
                routeId: "TRACE_PAGE",
            },
            {
                title: "Tracedetail",
                path: ":projectId/trace/:assetId/more",
                element: <TraceDetailPage />,
                routeId: "TRACE_DETAIL_PAGE",
            },
        ],
    },
    {
        title: "",
        path: "/token-app",
        element: <TaaSCustomerAppLayout />,
        routeId: "TOKEN_APP_LAYOUT",
        children: [
            {
                title: "Landing",
                path: ":projectId",
                element: (
                    <RequireCustomerAuth>
                        <CustomerDashboardPage />
                    </RequireCustomerAuth>
                ),
                routeId: "LANDING_PAGE",
            },
            {
                title: "Landing",
                path: ":projectId/:paymentId",
                element: (
                    <RequireCustomerAuth>
                        <FundingDetail />
                    </RequireCustomerAuth>
                ),
                routeId: "LANDING_PAGE",
            },
            // {
            //     title: "Purchase",
            //     path: ":projectId/mint",
            //     element: <TokenPurchasePage />,
            //     routeId: "TOKEN_PURCHASE_PAGE",
            // },
            {
                title: "Auth",
                path: ":projectId/login",
                element: <CustomerAuthPage />,
                routeId: "TOKEN_AUTH_PAGE",
            },
            {
                title: "Funding Page",
                path: "fund/:projectId",
                element: (
                    <RequireCustomerAuth>
                        <TokenPurchasePage />
                    </RequireCustomerAuth>
                ),
                routeId: "FUNDING_PAGE",
            },
            {
                title: "Signout",
                path: ":projectId/logout",
                element: <TaasSignoutPage />,
                routeId: "TOKEN_SIGNOUT",
            },
        ],
    },
    {
        title: "SignIn",
        path: "/signin",
        element: (
            <PublicOnly>
                <SignInPage />
            </PublicOnly>
        ),
        routeId: "SIGNIN",
    },
    {
        title: "Register",
        path: "/register",
        element: (
            <PublicOnly>
                <SignUpPage />
            </PublicOnly>
        ),
        routeId: "SIGNUP",
    },
    {
        title: "Signout",
        path: "/logout",
        element: <SignoutPage />,
        routeId: "SIGNOUT",
    },
];

// Returns Route ID Dictionary
export const ROUTES = getRouteObj(routesConfig);
