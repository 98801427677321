import { useState, useMemo } from "react";
import { BasicTable } from "../../../../components/tables";
import { useGetAssetsListQuery } from "../../../../services/supplyChainService";
import { useDownloadFileQuery } from "../../../../services/fileManagementService";
import { Form } from "../../../../components/forms";
import FilterSelect from "../../../../components/forms/FilterSelect";
import Typography from "../../../../components/Typography/Typography";
import { Icon } from "../../../../components/Icon";
import { useModal } from "../../../../hooks/modalsHooks";

// Simplified FileDisplay component
const FileDisplay = ({ fileId, fileName, type }) => {
    const { data: fileData } = useDownloadFileQuery(
        { fileId },
        {
            skip: !fileId,
        },
    );

    const handleClick = () => {
        if (fileData?.data?.file) {
            window.open(fileData.data.file, "_blank");
        }
    };

    return (
        <div
            onClick={handleClick}
            style={{ cursor: "pointer" }}
            className="d-flex align-items-center"
        >
            <span
                className="d-flex align-items-center"
                style={{ color: "#4f7a96" }}
            >
                <i
                    className={`bi ${type === "image" ? "bi-image" : "bi-file-earmark"} me-2`}
                ></i>
                {fileName || "View File"}
                <i
                    className="bi bi-caret-right-fill ms-2"
                    style={{ fontSize: "1rem" }}
                ></i>
            </span>
        </div>
    );
};

// Helper functions defined outside component to avoid initialization issues
const processFieldArray = (formData, info) => {
    if (!Array.isArray(formData)) return;

    formData.forEach((field) => {
        if (field.type === "group") {
            field.value.flat().forEach((groupField) => {
                info.push({
                    label: `${field.label} ${field.groupId} - ${groupField.label}`,
                    value: ["image", "file"].includes(groupField.type)
                        ? {
                              type: groupField.type,
                              fileId: groupField.value,
                              fileName: groupField.originalFileName,
                          }
                        : groupField.value,
                    isFile: ["image", "file"].includes(groupField.type),
                });
            });
        } else {
            info.push({
                label: field.label,
                value: ["image", "file"].includes(field.type)
                    ? {
                          type: field.type,
                          fileId: field.value,
                          fileName: field.originalFileName,
                      }
                    : field.value,
                isFile: ["image", "file"].includes(field.type),
            });
        }
    });
};

const processAssetDependencies = (dependencies) => {
    if (!Array.isArray(dependencies) || !dependencies.length) return "N/A";

    return dependencies
        .map((dep) => `${dep?.asset?.asset_id || "N/A"} (${dep?.qty || 0})`)
        .join(", ");
};

const processFormData = (asset) => {
    const info = [];

    // Process asset's own form data
    processFieldArray(asset?.form_data, info);

    return info.length ? info : [{ label: "No Info", value: "N/A" }];
};

const formatCreatedBy = (user) => {
    if (!user) return "N/A";
    const { first_name = "", last_name = "" } = user;
    const fullName = `${first_name} ${last_name}`.trim();
    return fullName || "N/A";
};

const BatchRecords = ({ id, selectedBatch }) => {
    // 1. Hooks
    const [materialType, setMaterialType] = useState("");
    const { showModal } = useModal();
    const { data: assetsResponse, isLoading: isLoadingAssets } =
        useGetAssetsListQuery({
            projectId: id,
            batchId: selectedBatch.value,
            skip: !id,
        });

    // 2. Derived state with useMemo
    const assetsList = useMemo(
        () => assetsResponse?.data || [],
        [assetsResponse],
    );

    const materialTypeOptions = useMemo(() => {
        const uniqueTypes = [
            ...new Set(
                assetsList
                    .map((asset) => asset.asset_type?.type_name)
                    .filter(Boolean),
            ),
        ];

        return [
            { value: "", label: "All Material Types" },
            ...uniqueTypes.map((type) => ({
                value: type,
                label: type,
            })),
        ];
    }, [assetsList]);

    const assetData = useMemo(() => {
        if (!assetsList?.length) return [];

        return assetsList.map((asset) => ({
            batchId: asset?.batch?.id || "N/A",
            assetId: asset?.asset_id || "N/A",
            uuid: asset?.uuid || `asset-${Math.random()}`,
            node: asset?.node?.name || "N/A",
            materialType: asset?.asset_type?.type_name || "N/A",
            quantity: `${asset?.qty || "N/A"} ${asset?.unit?.symbol || ""}`,
            dependencies: processAssetDependencies(asset?.dependencies_info),
            additionalInfo: processFormData(asset),
            createdBy: formatCreatedBy(asset?.created_by),
            createdByAvatar: asset?.created_by?.avatar || "/images/avatar.svg",
            createdAt: asset?.created_at || "N/A",
            typeOfMaterial: asset?.asset_type?.material_type || "N/A",
        }));
    }, [assetsList]);

    const filteredAssetData = useMemo(() => {
        if (!assetData?.length) return [];
        return materialType
            ? assetData.filter((asset) => asset?.materialType === materialType)
            : assetData;
    }, [materialType, assetData]);

    const renderExpandedContent = (rowData) => {
        const info = rowData.additionalInfo;

        if (info.length === 1 && info[0].label === "No Info") {
            return (
                <div className="expanded-info p-3">
                    No additional information available
                </div>
            );
        }

        return (
            <div className="expanded-info p-3">
                <Typography variant="h3" className="mb-3">
                    Additional Information
                </Typography>
                <table className="expanded-table">
                    <tbody>
                        {info.map((item, index) => (
                            <tr key={index}>
                                <td className="key-column">{item.label}</td>
                                <td className="value-column">
                                    {item.isFile ? (
                                        <FileDisplay
                                            fileId={item.value.fileId}
                                            fileName={item.value.fileName}
                                            type={item.value.type}
                                        />
                                    ) : (
                                        item.value
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const handleTraceClick = (assetId) => {
        showModal({
            modalType: "TraceAssetModal",
            modalProps: {
                assetLink: `/app/${id}/trace/${assetId}`,
            },
        });
    };

    // 4. Loading check
    if (isLoadingAssets) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div>
                <Form initialValues={{ materialType }} enableReinitialize>
                    <FilterSelect
                        name="materialType"
                        label="Filter by Material Type:"
                        options={materialTypeOptions}
                        placeholder="Select Material Type"
                        onChange={(event) =>
                            setMaterialType(event?.value || "")
                        }
                    />
                </Form>
            </div>
            <div className="mt-4">
                {filteredAssetData && (
                    <BasicTable
                        data={filteredAssetData}
                        columns={[
                            { header: "Material", accessor: "assetId" },
                            {
                                header: "Material Type",
                                accessor: "materialType",
                            },
                            { header: "Quantity", accessor: "quantity" },
                            {
                                header: "Dependencies",
                                accessor: "dependencies",
                            },
                            {
                                header: "Created date",
                                accessor: "createdAt",
                                Cell: ({ cell: { value } }) => (
                                    <div>
                                        {value !== "N/A"
                                            ? new Date(value)
                                                  .toLocaleDateString("en-US", {
                                                      year: "numeric",
                                                      month: "2-digit",
                                                      day: "2-digit",
                                                  })
                                                  .replace(/\//g, "-")
                                            : "N/A"}
                                    </div>
                                ),
                            },
                            {
                                header: "",
                                accessor: "action",
                                Cell: ({ row }) => (
                                    <div className="d-flex flex-row justify-content-end">
                                        {
                                        row.original.typeOfMaterial === "FINAL" && (
                                            <Icon
                                                src="/icons/routing.svg"
                                                className="mx-2"
                                                height={15}
                                                width={15}
                                                onClick={() => {
                                                    handleTraceClick(
                                                        row.original.assetId,
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                ),
                            },
                        ]}
                        isSortable={false}
                        isPaginated={filteredAssetData?.length > 5}
                        pageSize={5}
                        currentPage={0}
                        showCheckBox={false}
                        renderExpandedContent={renderExpandedContent}
                    />
                )}
            </div>
        </div>
    );
};

export default BatchRecords;
