import { useState } from "react";
import Sidebar from "./Sidebar";
import { useParams } from "react-router-dom";
import "./sidebar.scss";

export default function SidebarLayout({
    pageData,
    defaultPage = "dashboard",
    projectData,
    assetResponse,
    fundRaise,
    fundingLoading,
    brandLogo,
}) {
    const { tabName } = useParams();

    const [activePath, setActivePath] = useState(tabName ?? defaultPage);
    const activePage = pageData.find((page) => page.path === activePath);

    const sidebarItems = pageData.map((page) => ({
        name: page.name,
        path: page.path,
        active: page.path === activePage?.path,
        icon: page.icon,
    }));

    return (
        <div className="h-100 min-vh-100 w-100 d-flex flex-col">
            <Sidebar
                sidebarItems={sidebarItems}
                onChangeItem={setActivePath}
                projectData={projectData}
                fundRaise={fundRaise}
                assetResponse={assetResponse}
                fundingLoading={fundingLoading}
                brandLogo={brandLogo}
            />
            <div className="content-area pt-2">
                <div className="w-100 h-100">{activePage?.component}</div>
            </div>
        </div>
    );
}
