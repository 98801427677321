import { useState } from "react";
import { useModal } from "../../../../hooks/modalsHooks";
import Typography from "../../../../components/Typography/Typography";
import ModalLayout from "../../../../components/modals/ModalLayout";
import Form from "../../../../components/forms/Form";
import TextField from "../../../../components/forms/TextField";
import TextArea from "../../../../components/forms/TextArea";
import LocationSearchSelect from "../../../../components/forms/LocationSearch";
import UnifiedFileUpload from "../../../../components/forms/UnifiedFileUpload";
import Button from "../../../../components/Button/Button";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useUploadFileMutation } from "../../../../services/fileManagementService";
import { useLazySearchLocationsQuery } from "../../../../services/locationService";
import {
    useCreateNodeMutation,
    useEditNodeMutation,
} from "../../../../services/supplyChainService";
import { logEvent } from "@amplitude/analytics-browser";

export default function AddLocationPopupModal({
    title,
    onSubmit,
    id,
    setLoading = () => {},
    onLocationAdded,
    editMode = false,
    nodeData = null,
}) {
    const { hideModal, setModalLoading } = useModal();
    const [isLoading, setIsLoading] = useState(false);
    const [nodeImage, setNodeImage] = useState(nodeData?.node_image || null);
    const [uploadFile] = useUploadFileMutation();
    const [addNode] = useCreateNodeMutation();
    const [editNode] = useEditNodeMutation();

    const validationSchema = Yup.object().shape({
        nodeTitle: Yup.string()
            .trim("The input must not be empty or just spaces")
            .required("The input must not be empty or just spaces"),
        description: Yup.string()
            .trim("The input must not be empty or just spaces")
            .required("The input must not be empty or just spaces"),
        location: Yup.object()
            .shape({
                name: Yup.string().required("Location name is required"),
                meta: Yup.object().shape({
                    lon: Yup.string().required("Longitude is required"),
                    lat: Yup.string().required("Latitude is required"),
                }),
            })
            .required("Location is required"),
        nodeImage: Yup.mixed().test(
            "imageRequired",
            "Image is required",
            function (value) {
                // Consider both the form value and the nodeImage state
                return editMode && nodeData?.node_image
                    ? true
                    : value !== null || nodeImage !== null;
            },
        ),
    });

    const handleFormSubmit = async (values) => {
        setIsLoading(true);
        setLoading(true);
        setModalLoading(true);
        logEvent({
            eventName: "node_creation_initiated",
            eventProperties: {},
        });

        try {
            let imageId = undefined;

            if (nodeImage instanceof File) {
                // Only upload if it's a new file
                const formData = new FormData();
                formData.append("file", nodeImage);

                const uploadResponse = await uploadFile({
                    file: formData,
                }).unwrap();
                if (!uploadResponse?.data?.id) {
                    throw new Error("File upload failed");
                }

                imageId = uploadResponse.data.id;
            }

            const nodePayload = {
                name: values.nodeTitle,
                description: values.description,
                location: values.location.name,
                location_meta_data: values.location.meta,
            };

            // Only add node_image to payload if we have a new image or if we're not in edit mode
            if (!editMode || imageId !== undefined) {
                nodePayload.node_image = imageId || nodeImage;
            }

            let response;
            if (editMode) {
                response = await editNode({
                    id,
                    nodeId: nodeData.id,
                    ...nodePayload,
                }).unwrap(); // Add .unwrap() to properly handle RTK Query errors
            } else {
                response = await addNode({
                    id,
                    ...nodePayload,
                }).unwrap(); // Add .unwrap() to properly handle RTK Query errors
            }

            if (response.data) {
                toast.success(
                    `Location ${editMode ? "Updated" : "Added"} Successfully!`,
                );
                hideModal();
                if (onLocationAdded) {
                    onLocationAdded(response.data);
                }
            }
        } catch (error) {
            // Enhanced error handling
            const errorMessage =
                error.data?.message ||
                error.message ||
                "An unexpected error occurred";
            toast.error(
                `Error ${editMode ? "updating" : "adding"} location: ${errorMessage}`,
            );
        } finally {
            setIsLoading(false);
            setLoading(false);
            setModalLoading(false);
        }
    };

    const handleClose = () => {
        hideModal();
    };

    const handleFileSelected = (file) => {
        if (file instanceof File || file === null || typeof file === "string") {
            setNodeImage(file);
        }
    };

    return (
        <ModalLayout className="modal-dialog-centered mx-2" width={500}>
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 p-4 pb-0 d-flex justify-content-center">
                        <Typography variant="h2">
                            {editMode ? "Edit Location" : "Add New Location"}
                        </Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="w-100 pt-0 p-4 d-flex justify-content-center">
                        <Form
                            initialValues={{
                                nodeTitle: nodeData?.name || "",
                                description: nodeData?.description || "",
                                location: nodeData
                                    ? {
                                          value: nodeData.location,
                                          label: nodeData.location_meta_data
                                              .display_name,
                                          name: nodeData.location,
                                          meta: nodeData.location_meta_data,
                                      }
                                    : null,
                                nodeImage:
                                    nodeImage || nodeData?.node_image || null, // Initialize with existing image
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleFormSubmit}
                        >
                            <div className="m-3">
                                <TextField
                                    name="nodeTitle"
                                    label="Node title"
                                    required
                                />
                            </div>
                            <div className="m-3">
                                <TextArea
                                    name="description"
                                    label="Descriptions"
                                    required
                                />
                            </div>
                            <div className="m-3">
                                <LocationSearchSelect
                                    name="location"
                                    label="Location"
                                    placeholder="Search for a location..."
                                    searchLocationsHook={
                                        useLazySearchLocationsQuery
                                    }
                                    defaultValue={
                                        nodeData
                                            ? {
                                                  value: nodeData.location,
                                                  label: nodeData
                                                      .location_meta_data
                                                      .display_name,
                                              }
                                            : null
                                    }
                                    isSearchable={true}
                                    isClearable={true}
                                />
                            </div>
                            <div className="m-3">
                                <UnifiedFileUpload
                                    name="nodeImage"
                                    fileType="image"
                                    label="Promo Image"
                                    onFileSelected={handleFileSelected}
                                    initialFile={
                                        nodeData?.node_image
                                            ? {
                                                  url: nodeData.node_image,
                                                  name: "Current Image",
                                                  isExisting: true,
                                              }
                                            : null
                                    }
                                    selectedFile={nodeImage}
                                    width="100%"
                                    value={nodeImage} // Add value prop
                                />
                            </div>
                            {isLoading && (
                                <div className="text-center d-flex justify-content-center">
                                    <div className="spinner-grow" role="status">
                                        <span className="visually-hidden">
                                            Loading...
                                        </span>
                                    </div>
                                </div>
                            )}
                            <div className="my-4 d-flex justify-content-end flex-row align-items-center">
                                <Button
                                    type="button"
                                    className="d-flex align-items-center btn btn-secondary"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className="d-flex align-items-center btn btn-primary ms-2"
                                    isLoading={isLoading}
                                >
                                    {editMode ? "Update" : "Add"} Location
                                </Button>
                            </div>
                        </Form>
                    </div>
                </ModalLayout.Body>
            </div>
        </ModalLayout>
    );
}
