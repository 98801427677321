import { useMemo, useState, useEffect } from "react";
import { useModal } from "../../../hooks/modalsHooks";
import Typography from "../../../components/Typography/Typography";
import ModalLayout from "../../../components/modals/ModalLayout";
import {
    useCreateAssetTypeMutation,
    useUpdateAssetTypeMutation,
    useGetUnitsQuery,
    useGetAssetsTypeQuery,
    useGetNodesListQuery,
} from "../../../services/supplyChainService";
import { toast } from "react-toastify";
import { logEvent } from "../../../utils/amplitudeUtlis";
import * as Yup from "yup";
import Form from "../../../components/forms/Form";
import TextField from "../../../components/forms/TextField";
import Button from "../../../components/Button/Button";
import Select from "../../../components/forms/Select";
import SearchableInput from "../../../components/forms/Search";
import { FormRenderer } from "./FormRenderer";

// Constants outside component
const materialTypeOptions = [
    { label: "Raw Material", value: "RAW" },
    { label: "External", value: "EXTERNAL" },
    { label: "Final Product", value: "FINAL" },
];

const ERROR_MESSAGES = {
    DUPLICATE_MATERIAL: "A material with this name already exists.",
    NETWORK_ERROR: "Network error occurred. Please check your connection.",
    UNKNOWN_ERROR: "An unexpected error occurred. Please try again.",
};

const handleApiError = (error) => {
    if (!error?.data?.errors) return ERROR_MESSAGES.UNKNOWN_ERROR;

    const firstError = error.data.errors[0];

    // Handle nested dependency_config array error
    if (firstError?.dependency_config?.[0]) {
        return firstError.dependency_config[0];
    }

    // Handle direct dependency_config error
    if (firstError?.dependency_config) {
        return firstError.dependency_config;
    }

    if (
        typeof firstError === "string" &&
        firstError.includes("duplicate key value")
    ) {
        return ERROR_MESSAGES.DUPLICATE_MATERIAL;
    }

    return ERROR_MESSAGES.UNKNOWN_ERROR;
};

export default function MaterialPopup({
    id,
    onMaterialAdded,
    editMode = false,
    materialData = null,
}) {
    // Service hooks
    const { hideModal, showModal, setModalLoading } = useModal();
    const [createAssetType] = useCreateAssetTypeMutation();
    const [updateAssetType] = useUpdateAssetTypeMutation();
    const { data: unitsData } = useGetUnitsQuery();
    const { data: assetTypesResponse } = useGetAssetsTypeQuery(id, {
        skip: !id,
    });
    const { data: nodesResponse } = useGetNodesListQuery(id, { skip: !id });

    const nodeData = useMemo(() => nodesResponse?.data || [], [nodesResponse]);

    // State declarations
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState([]);
    const [formGroups, setFormGroups] = useState([]);
    const [selectedDependencies, setSelectedDependencies] = useState([]);
    const [showDependencySearch, setShowDependencySearch] = useState(false);
    const [locationValue, setLocationValue] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);

    useEffect(() => {
        if (!nodeData) setLocationOptions([]);
        setLocationOptions([
            ...nodeData.map((node) => ({
                label: node.name,
                value: node.id,
            })),
            { label: "Add new location", value: "add_new_location" },
        ]);
    }, [nodeData]);

    // Effects
    useEffect(() => {
        if (editMode && materialData) {
            const nodeId = materialData?.node?.id || materialData?.node;
            setLocationValue(nodeId);
            setFormFields(materialData.form_config?.fields || []);
            setFormGroups(materialData.form_config?.groups || []);
            setShowDependencySearch(
                !!materialData.dependency_config?.dependencies?.length,
            );
            setSelectedDependencies(
                (materialData.dependency_config?.dependencies || []).map(
                    (dep) => ({
                        id: dep.asset_type_id,
                        value: dep.label,
                        label: dep.label,
                    }),
                ),
            );
        }
    }, [editMode, materialData]);

    // Memoized values
    const assetTypes = useMemo(() => {
        if (!assetTypesResponse?.data) return [];
        return assetTypesResponse.data
            .filter((at) => !editMode || at.id !== materialData?.id)
            .map((at) => ({
                label: at.type_name,
                value: at.type_name,
                id: at.id,
            }));
    }, [assetTypesResponse, editMode, materialData]);

    const categoryOptions = useMemo(() => {
        if (!unitsData?.data) return [];
        return unitsData.data.map((category) => ({
            label:
                category.name.charAt(0).toUpperCase() + category.name.slice(1),
            value: category.id,
            name: category.name,
        }));
    }, [unitsData]);

    const initialValues = useMemo(
        () =>
            editMode
                ? {
                      assetTitle: materialData?.type_name || "",
                      unitCategory: materialData?.unit_category?.id || "",
                      materialType: materialData?.material_type || "",
                      location:
                          materialData?.node?.id || materialData?.node || "",
                  }
                : {
                      assetTitle: "",
                      unitCategory: "",
                      materialType: "",
                      location: "",
                  },
        [editMode, materialData],
    );

    // Event handlers
    const handleLocationChange = (event) => {
        event.preventDefault(); // Prevent form submission
        const selectedValue = event.target.value;
        if (!selectedValue) return;

        if (selectedValue === "add_new_location") {
            showModal({
                modalType: "AddLocationPopupModal",
                modalProps: {
                    onSubmit: onLocationAdded,
                    id,
                    setLoading: setIsLoading,
                    onLocationAdded: (newLocation) => {
                        setLocationValue(newLocation.id);
                    },
                },
            });
        } else {
            setLocationValue(selectedValue);
        }
    };

    const onLocationAdded = (newLocation) => {
        const newOption = { label: newLocation.name, value: newLocation.id };
        setLocationOptions((prevOptions) => {
            const filteredOptions = prevOptions.filter(
                (opt) => opt.value !== "add_new_location",
            );
            return [
                ...filteredOptions,
                newOption,
                { label: "Add new location", value: "add_new_location" },
            ];
        });
        setLocationValue(newLocation.id);
    };

    const handleSubmit = async (values) => {
        setIsLoading(true);
        setModalLoading(true);

        const formConfig = {
            fields: formFields,
            groups: formGroups,
        };

        const dependencyConfig = {
            dependencies: selectedDependencies.map((dep) => ({
                asset_type_id: dep.id,
                label: dep.value,
            })),
        };

        try {
            const payload = editMode
                ? {
                      id,
                      assetTypeId: materialData.id,
                      form_config: formConfig,
                      dependency_config: dependencyConfig,
                  }
                : {
                      id,
                      asset_type_name: values.assetTitle,
                      unit_category: values.unitCategory,
                      node: locationValue,
                      material_type: values.materialType,
                      form_config: formConfig,
                      dependency_config: dependencyConfig,
                  };

            const response = editMode
                ? await updateAssetType(payload)
                : await createAssetType(payload);

            if ("error" in response) {
                const errorMessage = handleApiError(response.error);
                toast.error(errorMessage);
                setIsLoading(false);
                setModalLoading(false);
                return;
            }

            if (!editMode && onMaterialAdded) {
                onMaterialAdded(response.data.data);
            }

            toast.success(
                editMode
                    ? "Material updated successfully"
                    : "Material created successfully",
            );
            hideModal();
        } catch (error) {
            const isNetworkError = !error.response && !error.status;
            const errorMessage = isNetworkError
                ? ERROR_MESSAGES.NETWORK_ERROR
                : ERROR_MESSAGES.UNKNOWN_ERROR;

            toast.error(errorMessage);
            logEvent({
                eventName: editMode
                    ? "record_type_update_error"
                    : "record_type_creation_error",
                eventProperties: {
                    error: error.message,
                    networkError: isNetworkError,
                    payload: values,
                },
            });
        } finally {
            setIsLoading(false);
            setModalLoading(false);
        }
    };

    // Add this new handler function near your other handlers
    const handleDependencyToggle = (e) => {
        // Stop the event from bubbling up to the form
        e.stopPropagation();
        const newValue = !showDependencySearch;
        setShowDependencySearch(newValue);
        if (!newValue) {
            setSelectedDependencies([]);
        }
    };

    // Render
    return (
        <ModalLayout className="modal-dialog-centered mx-2" width={600}>
            <div className="card d-flex justify-content-center">
                <ModalLayout.Header>
                    <div className="w-100 p-4 pb-0 d-flex justify-content-center">
                        <Typography variant="h2">
                            {editMode ? "Edit Material" : "Add New Material"}
                        </Typography>
                    </div>
                </ModalLayout.Header>
                <ModalLayout.Body>
                    <div className="w-100 pt-0 p-4">
                        <Form
                            initialValues={initialValues}
                            onSubmit={handleSubmit}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validationSchema={Yup.object().shape({
                                assetTitle: Yup.string()
                                    .trim()
                                    .required("Required"),
                            })}
                        >
                            <div className="m-3">
                                <TextField
                                    name="assetTitle"
                                    label="Material Name"
                                    disabled={editMode}
                                    required
                                />
                            </div>
                            <div className="m-3">
                                <Select
                                    name="unitCategory"
                                    label="Unit Category"
                                    options={categoryOptions}
                                    disabled={editMode}
                                    required
                                />
                            </div>
                            <div className="m-3">
                                <Select
                                    name="location"
                                    label="Location"
                                    options={locationOptions}
                                    onChange={handleLocationChange}
                                    value={
                                        editMode
                                            ? initialValues.location
                                            : locationValue
                                    }
                                    disabled={editMode}
                                    required
                                />
                            </div>
                            <div className="m-3">
                                <Select
                                    name="materialType"
                                    label="Material Type"
                                    options={materialTypeOptions}
                                    disabled={editMode}
                                    required
                                />
                            </div>
                            <div className="m-3">
                                <div className="toggle-section">
                                    <div className="toggle-header">
                                        <div className="toggle-label">
                                            Configure Asset Dependencies
                                        </div>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                role="switch"
                                                id="assetDependencyToggle"
                                                checked={showDependencySearch}
                                                onClick={(e) =>
                                                    e.stopPropagation()
                                                }
                                                onChange={
                                                    handleDependencyToggle
                                                }
                                            />
                                        </div>
                                    </div>

                                    {showDependencySearch && (
                                        <div className="toggle-content">
                                            <SearchableInput
                                                name="dependency"
                                                label="Search Dependencies"
                                                options={assetTypes}
                                                onSelect={(option) => {
                                                    if (
                                                        !selectedDependencies.find(
                                                            (dep) =>
                                                                dep.value ===
                                                                option.value,
                                                        )
                                                    ) {
                                                        setSelectedDependencies(
                                                            [
                                                                ...selectedDependencies,
                                                                {
                                                                    ...option,
                                                                    id: option.id,
                                                                },
                                                            ],
                                                        );
                                                    }
                                                }}
                                            />
                                            <div className="selected-dependencies mt-3">
                                                {selectedDependencies.map(
                                                    (dep, index) => (
                                                        <span
                                                            key={index}
                                                            className="badge me-2 mb-2 p-2"
                                                        >
                                                            {dep.label}
                                                            <i
                                                                className="bi bi-x-circle ms-2"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    fontSize:
                                                                        "11px",
                                                                    color: "#1C8AD1",
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedDependencies(
                                                                        selectedDependencies.filter(
                                                                            (
                                                                                _,
                                                                                i,
                                                                            ) =>
                                                                                i !==
                                                                                index,
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                        </span>
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Typography variant="h3" className="m-3">
                                Data Template
                            </Typography>
                            <FormRenderer
                                formFields={formFields}
                                setFormFields={setFormFields}
                                formGroups={formGroups}
                                setFormGroups={setFormGroups}
                                editMode={editMode}
                            />
                            <div className="my-4 d-flex justify-content-end flex-row align-items-center">
                                <Button
                                    type="button"
                                    variant="secondary"
                                    onClick={() => hideModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    className="ms-2"
                                    isLoading={isLoading}
                                >
                                    {editMode ? "Save Changes" : "Add Material"}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </ModalLayout.Body>
            </div>
        </ModalLayout>
    );
}
