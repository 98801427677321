export const ProjectTemplates = {
    "SUPPLY_CHAIN": "SUPPLY_CHAIN",
    "TOKENIZATION": "BAO"
}

export function getProjectTemplateFromAPI(apiTemaplte) {
    switch (apiTemaplte) {
        case "SUPPLY_CHAIN":
            return ProjectTemplates.SUPPLY_CHAIN;

        case "BAO":
            return ProjectTemplates.TOKENIZATION;

        default:
            return null;
    }
}

export function getProjectTemplateName(template) {
    switch (template) {
        case "SUPPLY_CHAIN":
            return "Supply chain traceability";

        case "BAO":
            return "Tokenization";

        default:
            return "Other";
    }
}

export const string_to_snake_case = (str) => {
    // First, handle existing underscores by replacing them with spaces temporarily
    const withoutExistingUnderscores = str.replace(/_/g, ' ');
    
    // Then apply the standard snake case conversion
    return withoutExistingUnderscores
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '') // Remove special characters except spaces
        .replace(/\s+/g, '_'); // Replace spaces with single underscore
};

export function formatDate (dateString) {
    return new Date(dateString).toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
};