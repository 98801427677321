import React, {
    useEffect,
    useState,
    useRef,
    forwardRef,
    useImperativeHandle,
    useCallback,
} from "react";
import { useField } from "formik";
import Typography from "../Typography/Typography";
import "./form.scss";

const UnifiedFileUpload = forwardRef((props, ref) => {
    const {
        name,
        width,
        height,
        onFileSelected,
        selectedFile,
        initialFile,
        label,
        fileType = "all",
        maxFileSize = 2 * 1024 * 1024, // 2MB default
        currentUrl,
        value,  // Add value prop
        ...rest
    } = props;

    const [field, meta, helpers] = useField(name);
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(initialFile?.url || null);
    const [localError, setLocalError] = useState(null);
    const inputRef = useRef(null);

    const SUPPORTED_TYPES = {
        image: {
            extensions: ["jpg", "jpeg", "png"],
            mimeTypes: ["image/jpeg", "image/png", "image/jpg"],
        },
        document: {
            extensions: ["pdf", "doc", "docx", "xls", "xlsx"],
            mimeTypes: [
                "application/pdf",
                "application/msword",
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                "application/vnd.ms-excel",
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ],
        },
    };

    const getAllowedTypes = useCallback(() => {
        if (fileType === "image") return SUPPORTED_TYPES.image;
        if (fileType === "document") return SUPPORTED_TYPES.document;
        return {
            extensions: [
                ...SUPPORTED_TYPES.image.extensions,
                ...SUPPORTED_TYPES.document.extensions,
            ],
            mimeTypes: [
                ...SUPPORTED_TYPES.image.mimeTypes,
                ...SUPPORTED_TYPES.document.mimeTypes,
            ],
        };
    }, [fileType]);

    const validateFile = useCallback(
        (file) => {
            if (!file) return null;

            const allowedTypes = getAllowedTypes();
            const fileExtension = file.name.split(".").pop().toLowerCase();

            if (
                !allowedTypes.extensions.includes(fileExtension) &&
                !allowedTypes.mimeTypes.includes(file.type)
            ) {
                return `Unsupported file type. Please upload: ${allowedTypes.extensions.join(
                    ", "
                )}`;
            }

            if (file.size > maxFileSize) {
                return `File size exceeds ${
                    maxFileSize / (1024 * 1024)
                }MB limit.`;
            }

            return null;
        },
        [getAllowedTypes, maxFileSize]
    );

    const handleFile = useCallback(
        (selectedFile) => {
            if (!selectedFile) {
                setFile(null);
                setPreview(null);
                helpers.setValue(null);
                onFileSelected?.(null);
                return;
            }

            const error = validateFile(selectedFile);

            if (error) {
                setLocalError(error);
                helpers.setError(error);
                helpers.setTouched(true);
                helpers.setValue(null);
                setFile(null);
                setPreview(null);
                return;
            }

            setLocalError(null);
            helpers.setValue(selectedFile);
            helpers.setError(undefined);
            helpers.setTouched(true);
            setFile(selectedFile);

            if (selectedFile.type?.startsWith("image/")) {
                const previewUrl = URL.createObjectURL(selectedFile);
                setPreview(previewUrl);
            }

            onFileSelected?.(selectedFile);
        },
        [validateFile, helpers, onFileSelected]
    );

    const handleFileChange = useCallback(
        (event) => {
            const selectedFile = event.target.files?.[0];
            if (selectedFile) {
                handleFile(selectedFile);
            }
        },
        [handleFile]
    );

    const handleDrop = useCallback(
        (event) => {
            event.preventDefault();
            const droppedFile = event.dataTransfer.files?.[0];
            if (droppedFile) {
                handleFile(droppedFile);
            }
        },
        [handleFile]
    );

    const handleRemoveFile = useCallback(() => {
        setFile(null);
        setPreview(null);
        setLocalError(null);
        helpers.setValue(null);
        helpers.setError(null);
        helpers.setTouched(false);
        onFileSelected?.(null);
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    }, [helpers, onFileSelected]);

    useImperativeHandle(
        ref,
        () => ({
            clearFile: handleRemoveFile,
        }),
        [handleRemoveFile]
    );

    const renderThumbnail = useCallback(
        (fileOrUrl) => {
            if (!fileOrUrl) return null;

            // Handle URL string (existing image)
            if (typeof fileOrUrl === 'string') {
                return (
                    <img
                        src={fileOrUrl}
                        alt="Preview"
                        height="80px"
                        width="80px"
                        className="rounded"
                        style={{ objectFit: 'cover' }}
                    />
                );
            }

            // Handle File object
            if (fileOrUrl instanceof File) {
                if (fileOrUrl.type.startsWith("image/")) {
                    return (
                        <img
                            src={preview}
                            alt="Preview"
                            height="80px"
                            width="80px"
                            className="rounded"
                            style={{ objectFit: 'cover' }}
                        />
                    );
                }

                const iconMap = {
                    pdf: "bi-file-earmark-pdf",
                    doc: "bi-file-earmark-word",
                    docx: "bi-file-earmark-word",
                    xls: "bi-file-earmark-excel",
                    xlsx: "bi-file-earmark-excel",
                };

                const fileExtension = fileOrUrl.name.split(".").pop().toLowerCase();
                const iconClass = iconMap[fileExtension] || "bi-file-earmark";
                return <i className={`bi ${iconClass} fs-1 text-secondary`} />;
            }
            return null;
        },
        [preview]
    );

    const getFileNameFromUrl = (url) => {
        if (!url) return '';
        try {
            // Extract the filename from the path (before the query parameters)
            const pathMatch = url.match(/\/([^/?]+)\?/);
            if (pathMatch && pathMatch[1]) {
                return decodeURIComponent(pathMatch[1]);
            }
            return 'Current Image';
        } catch (e) {
            return 'Current Image';
        }
    };

    useEffect(() => {
        if (initialFile?.url && !file && !preview) {
            setPreview(initialFile.url);
            helpers.setValue(initialFile.url);
        }
    }, [initialFile, file, preview, helpers]);

    useEffect(() => {
        return () => {
            if (preview?.startsWith("blob:")) {
                URL.revokeObjectURL(preview);
            }
        };
    }, [preview]);

    useEffect(() => {
        // Set initial value when component mounts
        if (initialFile?.url && !field.value) {
            helpers.setValue(initialFile.url);
        }
        // Update form value when selectedFile changes
        if (selectedFile) {
            helpers.setValue(selectedFile);
        }
    }, [initialFile, selectedFile, helpers, field.value]);

    return (
        <div className="w-100">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            )}
            <section
                className="drag-drop d-flex flex-column"
                style={{ width, height }}
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
            >
                <div
                    className={`document-uploader ${
                        file ? "upload-box active" : "upload-box"
                    }`}
                >
                    <div className="upload-info d-flex flex-column align-items-center">
                        <i className="bi bi-cloud-arrow-up upload-icon"></i>
                        <Typography variant="body1" className="fw-bold mb-1">
                            Drag and drop your file here, or{" "}
                            <label
                                htmlFor={name}
                                className="browse-btn btn-link p-0"
                            >
                                browse
                            </label>
                        </Typography>
                        <Typography variant="body1" className="text-muted">
                            Limit {maxFileSize / (1024 * 1024)}MB per file.
                            Supported files:{" "}
                            {getAllowedTypes().extensions.join(", ")}
                        </Typography>
                    </div>
                    <input
                        id={name}
                        name={name}
                        type="file"
                        accept={getAllowedTypes().mimeTypes.join(",")}
                        hidden
                        ref={inputRef}
                        onChange={handleFileChange}
                        multiple={false}
                        {...rest}
                    />

                    {(file || selectedFile || initialFile?.url) && (
                        <div className="file-list d-flex flex-column gap-2 w-100 h-30">
                            <div className="file-list__container w-100 h-100 overflow-auto">
                                <div className="file-item d-flex justify-content-between align-items-center p-2 border rounded">
                                    <div className="file-info d-flex align-items-center gap-1 flex-grow-1">
                                        {renderThumbnail(selectedFile || currentUrl || file || initialFile?.url)}
                                        <Typography
                                            variant="body1"
                                            className="ms-2 m-0"
                                        >
                                            {file?.name || (initialFile?.url ? getFileNameFromUrl(initialFile.url) : "Current Image")}
                                        </Typography>
                                    </div>
                                    <div className="file-actions cursor-pointer">
                                        <i
                                            className="bi bi-x fs-3 text-secondary"
                                            onClick={handleRemoveFile}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            {(meta.touched || localError) && (meta.error || localError) && (
                <div className="text-danger mt-2">
                    {meta.error || localError}
                </div>
            )}
        </div>
    );
});

export default UnifiedFileUpload;
